import analytics from '@analytics';

export async function login(
  companyProfileId: string,
  email: string,
  password: string,
  rememberMe = false,
  shareholderHashId?: string
) {
  const res = await fetch('/api/hermes/auth/login', {
    body: JSON.stringify({
      shareholder_hash_id: shareholderHashId,
      user: {
        company_profile_id: companyProfileId,
        email,
        password,
        remember_me: rememberMe,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  const json = await res.json();

  if (json.error) {
    throw new Error(json.error.message);
  }
}

export async function logout() {
  const res = await fetch('/api/hermes/auth//logout', {
    method: 'POST',
  });

  const json = await res.json();

  if (json.error) {
    throw new Error(json.error.message);
  }

  analytics.reset();
}
