import { useMemo, useRef, useState } from 'react';
import VerifyHoldingsLiteComplete from '@/components/auth/verify-holdings-lite/complete';
import VerifyHoldingsLiteVerify from '@/components/auth/verify-holdings-lite/verify';
import GenericModal from '@/components/modals/generic-modal';
import { ShareholderBadgeIcon } from '@/components/utils/shareholder-badge';
import { useCurrentCompany } from '@/contexts/current-company-context';

interface Props {
  onClose(): void;
  open: boolean;
}

const VerifyHoldingsOnPageModal: React.ComponentType<Props> = ({
  onClose,
  open,
}) => {
  const [step, setStep] = useState<'verify' | 'complete'>('verify');

  const buttonRef = useRef<HTMLButtonElement>(null);

  const {
    currentCompany: { name },
  } = useCurrentCompany();

  const renderContent = useMemo(() => {
    if (step === 'verify')
      return <VerifyHoldingsLiteVerify setStep={setStep} onClose={onClose} />;
    if (step === 'complete')
      return <VerifyHoldingsLiteComplete onClose={onClose} />;
    return null;
  }, [step, setStep, onClose]);

  const title = useMemo(() => {
    if (step === 'verify') return 'Verify your shareholdings';
    return null;
  }, [step]);

  const subtitle = useMemo(() => {
    if (step === 'verify')
      return `Verify your status as a shareholder of ${name} to enable us better understand your position.`;
    return null;
  }, [step, name]);

  return (
    <GenericModal
      bottomModal
      headerDescriptionText={subtitle}
      headerImage={
        step === 'verify' ? (
          <div className="mb-8 flex h-16 w-16 items-center justify-center rounded-full bg-company-primary">
            <ShareholderBadgeIcon className="h-[26px] w-[26px]" />
          </div>
        ) : null
      }
      headerText={title}
      initialFocus={buttonRef}
      open={open}
      onClose={onClose}
    >
      {renderContent}
    </GenericModal>
  );
};

export default VerifyHoldingsOnPageModal;
