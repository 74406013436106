// CustomiseCookies.tsx
import React from 'react';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { Controller, Control } from 'react-hook-form';
import { CookieConsent } from '@leaf/helpers';
import { Button, Typography } from '@leaf/ui';
import Checkbox from '@/components/utils/checkbox';

interface CustomiseCookiesProps {
  control: Control<CookieConsent>;
  onCancel: () => void;
  onSubmit: () => void;
}

const CustomiseCookies: React.FC<CustomiseCookiesProps> = ({
  control,
  onCancel,
  onSubmit,
}) => {
  return (
    <form
      className="relative mx-auto w-full overflow-y-auto"
      onSubmit={onSubmit}
    >
      <div className="mb-6 flex w-full flex-row items-center justify-between">
        <Typography className="font-heading !font-normal" variant="heading-4">
          Customise cookies
        </Typography>
        <button className="" onClick={() => onCancel()}>
          <XIcon className="size-5 stroke-company-accent-text" />
        </button>
      </div>
      <div className="mb-0 flex flex-col items-center gap-5 md:mb-3 md:flex-row md:gap-10">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="flex gap-3">
            <Checkbox
              checked
              disabled
              className="checkbox mt-0.5 size-4 checked:!bg-company-primary disabled:opacity-40"
              id="necessary"
            />
            <label
              className="flex flex-1 flex-col gap-1 text-center sm:text-left"
              htmlFor="necessary"
            >
              <Typography className="font-body font-semibold">
                Strictly necessary cookies
              </Typography>
              <Typography className="font-body">
                These are cookies that are necessary for our website to function
                and <strong>cannot be switched off</strong> in our systems. For
                example, they are used to store your privacy preferences or
                enable you to log into secure areas of our website.
              </Typography>
            </label>
          </div>
          <div className="flex gap-3">
            <Controller
              control={control}
              name="analytics"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  checked={value}
                  className="checkbox mt-0.5 size-4 checked:!bg-company-primary"
                  id="analytics"
                  onChange={() => onChange(!value)}
                />
              )}
            />
            <label
              className="flex flex-col gap-1 text-center sm:text-left"
              htmlFor="analytics"
            >
              <Typography className="font-body font-semibold">
                Performance cookies
              </Typography>
              <Typography className="font-body">
                These cookies allow us to count visits and traffic sources, so
                we can measure and improve the performance of our site and
                understand which parts of our website are the most and least
                popular. They do not identify you as an individual.
              </Typography>
            </label>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center gap-4 md:flex-col md:justify-end lg:flex-row">
          <Button
            className="order-2 text-company-primary-text"
            endIcon={<CheckIcon className="size-5" />}
            type="submit"
          >
            Accept
          </Button>
          <div
            className="order-1 cursor-pointer whitespace-nowrap rounded-lg bg-white/20 px-3 py-2 font-body transition-colors hover:bg-white/20 md:order-3 lg:order-1 "
            onClick={onCancel}
          >
            Cancel
          </div>
        </div>
      </div>
    </form>
  );
};

export default CustomiseCookies;
