// A hot fix for logo - TBR with a proper cropping system
import clsx from 'clsx';
import Image from 'next/legacy/image';
import AKOLogo from '@/public/images/ako-logo.jpg';
import COBRLogoTransparent from '@/public/images/cobra-logo-transparent.png';
import COBRLogoAlt from '@/public/images/cobra-logo-white-alt.png';
import COBRLogo from '@/public/images/cobra-logo-white.png';
import EMDLogo from '@/public/images/emd-logo.jpg';
import FusionLogoTransparent from '@/public/images/fusion-logo-transparent.png';
import FusionLogo from '@/public/images/fusion-logo.png';
import IPTLogo from '@/public/images/impact-minerals-logo.jpg';
import LTRLogoTransparent from '@/public/images/ltr-logo-transparent.png';
import LTRLogo from '@/public/images/ltr-logo.jpg';
import MAPLogo from '@/public/images/map-logo.png';
import MEULogo from '@/public/images/meu-logo.png';
import REELogo from '@/public/images/rarex-logo-wide.png';

interface Props {
  listingKey: string;
  logoUrl: string;
  marketKey: string;
}

export const AnnouncementBannerLogo: React.ComponentType<Props> = ({
  listingKey,
  logoUrl,
  marketKey,
}) => {
  const customizedLogo: { [key: string]: { [key: string]: JSX.Element } } = {
    asx: {
      ipt: (
        <Image
          priority
          alt={listingKey}
          height={46}
          quality={100}
          src={IPTLogo}
          width={99}
        />
      ),
      ltr: (
        <Image
          priority
          alt={listingKey}
          height={46}
          quality={100}
          src={LTRLogoTransparent}
          width={193}
        />
      ),
      rad: (
        <Image
          priority
          alt="RAD Logo"
          height={46}
          src="/images/rad-logo.svg"
          width={193}
        />
      ),
    },
    lse: {
      cobr: (
        <Image
          alt="COBR Logo"
          height={40}
          quality={100}
          src={COBRLogoAlt}
          width={147}
        />
      ),
      fab: (
        <Image
          alt={listingKey}
          height={38}
          quality={100}
          src={FusionLogoTransparent}
          width={91}
        />
      ),
    },
  };

  const market = marketKey.toLowerCase();
  const listing = listingKey.toLowerCase();

  return (
    (customizedLogo[market] && customizedLogo[market][listing]) ?? (
      <Image
        priority
        alt={listingKey}
        height={46}
        quality={100}
        src={logoUrl}
        width={46}
      />
    )
  );
};

export const FooterLogo: React.ComponentType<Props> = ({
  listingKey,
  logoUrl,
  marketKey,
}) => {
  const customizedLogo: { [key: string]: { [key: string]: JSX.Element } } = {
    asx: {
      ltr: (
        <div className="flex">
          <Image
            alt={listingKey}
            height={46}
            quality={100}
            src={LTRLogoTransparent}
            width={193}
          />
        </div>
      ),
      rad: (
        <div className="flex">
          <Image
            alt="RAD Logo"
            height={46}
            src="/images/rad-logo.svg"
            width={193}
          />
        </div>
      ),
    },
    lse: {
      cobr: (
        <Image
          alt="COBR Logo"
          height={40}
          quality={100}
          src={COBRLogo}
          width={147}
        />
      ),
      fab: (
        <Image
          alt={listingKey}
          height={38}
          quality={100}
          src={FusionLogo}
          width={91}
        />
      ),
    },
  };

  const market = marketKey.toLowerCase();
  const listing = listingKey.toLowerCase();

  return (
    (customizedLogo[market] && customizedLogo[market][listing]) ?? (
      <div
        className={clsx(
          'flex',
          market === 'asx' && listing === '1ad' && 'rounded-full bg-white'
        )}
      >
        <Image
          alt={listingKey}
          className="rounded-full"
          height={64}
          src={logoUrl}
          width={64}
        />
      </div>
    )
  );
};

export const HeaderLogo: React.ComponentType<Props> = ({
  listingKey,
  logoUrl,
  marketKey,
}) => {
  const customizedLogo: { [key: string]: { [key: string]: JSX.Element } } = {
    asx: {
      ako: (
        <Image
          alt={listingKey}
          height={36}
          quality={100}
          src={AKOLogo}
          width={140}
        />
      ),
      caz: (
        <Image
          alt="CAZ Logo"
          height={36}
          src="/images/caz-logo.png"
          width={120}
        />
      ),
      emd: (
        <Image
          alt={listingKey}
          height={46}
          quality={100}
          src={EMDLogo}
          width={162}
        />
      ),
      ipt: (
        <Image
          alt={listingKey}
          height={46}
          quality={100}
          src={IPTLogo}
          width={99}
        />
      ),
      ltr: (
        <Image
          alt={listingKey}
          height={46}
          quality={100}
          src={LTRLogo}
          width={193}
        />
      ),
      map: (
        <Image
          alt={listingKey}
          height={35}
          quality={100}
          src={MAPLogo}
          width={197}
        />
      ),
      meu: (
        <Image
          alt={listingKey}
          height={38}
          quality={100}
          src={MEULogo}
          width={179}
        />
      ),
      rad: (
        <Image
          alt="RAD Logo"
          height={46}
          src="/images/rad-logo.svg"
          width={100}
        />
      ),
      ree: (
        <Image
          alt={listingKey}
          height={54}
          quality={100}
          src={REELogo}
          width={108}
        />
      ),
    },
    lse: {
      cobr: (
        <Image
          alt={listingKey}
          height={38}
          quality={100}
          src={COBRLogoTransparent}
          width={140}
        />
      ),
      fab: (
        <Image
          alt={listingKey}
          height={38}
          quality={100}
          src={FusionLogo}
          width={91}
        />
      ),
    },
  };

  const market = marketKey.toLowerCase();
  const listing = listingKey.toLowerCase();

  return (
    (customizedLogo[market] && customizedLogo[market][listing]) ?? (
      <Image
        alt={listingKey}
        className="rounded-full"
        height={64}
        src={logoUrl}
        width={64}
      />
    )
  );
};
