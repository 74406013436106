const locales = ['en-AU', 'en-UK'];
type ValidLocale = (typeof locales)[number];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dictionaries: Record<ValidLocale, any> = {
  'en-AU': require('translations/en-AU.json'),
  'en-UK': require('translations/en-UK.json'),
};

export const getTranslator = (locale: ValidLocale | null) => {
  if (!locale || !dictionaries[locale]) {
    return (_key: string) => '';
  }
  const dictionary = dictionaries[locale];
  return (key: string, params?: { [key: string]: string | number }) => {
    let translation = key
      .split('.')
      .reduce((obj, key) => obj && obj[key], dictionary);
    if (!translation) {
      return key;
    }
    if (params && Object.entries(params).length) {
      Object.entries(params).forEach(([key, value]) => {
        translation = translation
          ? translation.replace(`{{ ${key} }}`, String(value))
          : 'unknown translation';
      });
    }
    return translation;
  };
};
