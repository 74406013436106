import { useState } from 'react';
import analytics from '@analytics';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, Typography } from '@leaf/ui';
import { useSubscribeMutation } from '@/apollo/generated';
import { useAlert } from '@/contexts/alert-context';

interface FormData {
  email: string;
  firstName?: string;
  lastName?: string;
}

interface SubscribeFormProps {
  event?: string;
  listingKey?: string;
  marketKey?: string;
}

const SubscribeForm: React.ComponentType<SubscribeFormProps> = ({
  event,
  listingKey,
  marketKey,
}) => {
  const { formState, handleSubmit, register } = useForm<FormData>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().trim().email().required(),
        firstName: yup.string().trim(),
        lastName: yup.string().trim(),
      })
    ),
  });

  const [showForm, toggleShowForm] = useState(true);

  const { formatAndShowError, showAlert } = useAlert();

  const [subscribe, { loading }] = useSubscribeMutation();

  const isSunrice = marketKey === 'asx' && listingKey === 'sgl';

  const onSubmit = handleSubmit(async (data) => {
    if (event) analytics.track(event);

    subscribe({
      variables: {
        input: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        },
      },
    })
      .then(() => {
        toggleShowForm(false);
        showAlert({
          description: 'Great, you are on the list to receive email updates',
          variant: 'success',
        });
      })
      .catch(formatAndShowError);
  });

  return (
    <form className="bg-transparent" onSubmit={onSubmit}>
      {/* The "xs" styles here are important for when clients embed the "/subscribe" page in an iframe */}
      {/* If you are going to change this form, make sure it still works in this iframe: */}
      {/* <iframe height="290" src="https://{ticker}.localhost.com:3003/subscribe" style="border: none; max-width: 100%" width="520"/> */}
      <div className={clsx(!isSunrice && 'h-[235px] xs:h-[185px]')}>
        {showForm ? (
          <div className="flex flex-col gap-3">
            <div className="grid grid-cols-1 gap-2 xs:grid-cols-2">
              <input
                className="input col-span-1 h-10 min-w-0 rounded-md transition-colors focus:border-gray-200 focus:shadow-none focus:ring-company-primary"
                placeholder="First name"
                type="text"
                {...register('firstName')}
              />
              <input
                className="input col-span-1 h-10 min-w-0 rounded-md transition-colors focus:border-gray-200 focus:shadow-none focus:ring-company-primary"
                placeholder="Last name"
                type="text"
                {...register('lastName')}
              />
              <input
                className={clsx(
                  'input col-span-1 h-10 min-w-0 flex-1 rounded-md transition-colors focus:border-gray-200 focus:shadow-none focus:ring-company-primary xs:col-span-2',
                  !!formState.errors.email && 'input-error'
                )}
                placeholder="Email (required)"
                type="email"
                {...register('email')}
              />
            </div>

            <Button
              color="company"
              disabled={formState.isSubmitting || loading}
              endIcon={<ArrowRightIcon className="size-4" />}
              type="submit"
            >
              Subscribe for our latest news
            </Button>

            <div className="flex items-center gap-x-2 md:self-stretch">
              <Typography className="font-body text-[12px] text-company-accent-text md:text-left">
                {isSunrice ? (
                  <>
                    By submitting your email address, you consent to the
                    collection, use and disclosure of your personal information
                    by SunRice and our IT partner InvestorHub in accordance with
                    the{' '}
                    <a
                      className="underline"
                      href="https://res.cloudinary.com/investorhub/image/upload/v1731619070/leaf-prod/283/manual_uploads/sunrice-investorhub-collection-statement.pdf"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Collection Statement
                    </a>
                    . SunRice and InvestorHub collect personal information from
                    you when you interact with the SunRice Investor Hub (through
                    cookies and similar technologies), answer questions, surveys
                    or submit forms and other information. This includes
                    information collected from or about the device you use,
                    including IP address, webpages you have visited and your
                    browsing behaviour. We use your information for the purposes
                    set out in the Collection Statement, which includes to
                    improve user experience, create user profiles and deliver
                    tailored marketing content to you. Please see{' '}
                    <a
                      className="underline"
                      href="https://www.sunrice.com.au/au-privacy-policy"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      SunRice&apos;s Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      className="underline"
                      href="https://investorhub.com/au-microsite-privacy-policy"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      InvestorHub&apos;s Privacy Policy
                    </a>{' '}
                    for information about how to access, correct or submit a
                    privacy complaint about your personal information. Your
                    emails are managed by InvestorHub, an investor engagement
                    platform. Unsubscribe anytime.
                  </>
                ) : (
                  <>
                    Emails are managed by InvestorHub, an investor engagement
                    platform. Unsubscribe anytime.
                  </>
                )}
              </Typography>
            </div>
          </div>
        ) : null}
      </div>
    </form>
  );
};

export default SubscribeForm;
