import { useEffect, useState } from 'react';
import cookie from 'cookie';
import { useRouter } from 'next/router';
import { Tooltip } from 'react-tooltip';
import { PREVIEW_COOKIE_NAME } from '@/components/utils/constants';

const ExitPreview: React.FC = () => {
  const router = useRouter();
  const { previewSecret } = router.query;
  const [paramRemoved, setParamRemoved] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  useEffect(() => {
    const cookies = cookie.parse(document.cookie);
    setIsPreviewMode(cookies[PREVIEW_COOKIE_NAME] !== undefined);
  }, []);

  useEffect(() => {
    if (!paramRemoved && previewSecret) {
      const removeQueryParam = (param: string) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete(param);
        const newPath = `${window.location.pathname}${
          queryParams.size ? `?${queryParams.toString()}` : ''
        }`;
        router.replace(newPath, undefined, { shallow: true });
        setParamRemoved(true);
      };

      removeQueryParam('previewSecret');
    }
  }, [paramRemoved, previewSecret, router]);

  const exitPreviewMode = () => {
    document.cookie = cookie.serialize(PREVIEW_COOKIE_NAME, '', {
      maxAge: -1,
      path: '/',
    });
    router.reload();
  };
  if (!isPreviewMode) {
    return null;
  }

  return (
    <div className="fixed left-[46%] top-0 z-50 flex origin-top-right items-center">
      <span className="absolute top-[-32px] z-[-1] flex h-[200%] w-full animate-ping rounded-full bg-amber-400" />
      <div
        className="relative cursor-pointer rounded-b-lg border-x border-b border-amber-100 bg-amber-400 px-4 pb-2 pt-1 font-medium text-amber-900 transition-colors hover:bg-amber-300"
        id="exit-preview"
        onClick={exitPreviewMode}
      >
        Previewing draft
      </div>
      <Tooltip
        anchorSelect="#exit-preview"
        className="relative"
        content="You are previewing unpublished changes. Click to view live site instead."
      />
    </div>
  );
};

export default ExitPreview;
