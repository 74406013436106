import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import {
  TrackingEventInput,
  useCreateTrackingEventMutation,
} from '@/apollo/generated';
import useIsSsr from '@/hooks/use-ssr';

const utmParams = [
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_term,',
];

export const useCreateTrackingEvent = (trackingEvent: TrackingEventInput) => {
  const [createTrackingEvent] = useCreateTrackingEventMutation({
    variables: { trackingEvent },
  });
  const isSsr = useIsSsr();

  return useEffect(() => {
    let mounted = true;

    if (mounted && !isSsr) {
      const createEvent = () => {
        createTrackingEvent().catch();
      };
      createEvent();
    }

    return () => {
      mounted = false;
    };
  }, [createTrackingEvent, isSsr]);
};

export const useCreateTrackingUtmEvent = () => {
  const router = useRouter();

  const [createTrackingEvent] = useCreateTrackingEventMutation();
  const isSsr = useIsSsr();

  return useEffect(() => {
    let mounted = true;

    const trackUtm = async () => {
      const utmEventKeys = Object.keys(router.query).filter((query) =>
        utmParams.includes(query)
      );

      if (utmEventKeys.length) {
        try {
          await createTrackingEvent({
            variables: {
              trackingEvent: {
                event: 'utm_event',
                eventMetadata: {
                  ...utmEventKeys.reduce(
                    (acc, cur) => ({
                      ...acc,
                      [cur]: router.query[`${cur}`],
                    }),
                    {}
                  ),
                  href: window.location.href,
                  pathname: window.location.pathname,
                  referrer: window.frames.self.document.referrer,
                  search: window.location.search,
                },
              },
            },
          });

          const constructNewParams = (search: string) => {
            const urlSearchParams = new URLSearchParams(search);

            // Delete all the UTM related query params after tracking
            // Need to do this to make sure we only track UTM once
            utmParams.forEach((utmKeyWeCare) => {
              if (urlSearchParams.has(utmKeyWeCare)) {
                urlSearchParams.delete(utmKeyWeCare);
              }
            });

            const searchString = urlSearchParams.toString();

            if (searchString) return `?${searchString}`;
            else return '';
          };

          // we don't want to use router.replace since we have router as a dependency in the hook, which would retrigger the useEffect and create a duplicate event
          window.history.replaceState(
            window.history.state,
            '',
            window.location.origin +
              window.location.pathname +
              constructNewParams(window.location.search)
          );
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };

    if (router.isReady && mounted && !isSsr) {
      trackUtm();
    }

    return () => {
      mounted = false;
    };
  }, [createTrackingEvent, isSsr, router.isReady, router.query]);
};
