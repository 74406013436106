import { InvestorHubRegisters } from '@/apollo/generated';

export type Registry = {
  address: string;
  email?: string;
  investorCentreName: string;
  key: `${InvestorHubRegisters}`;
  link: string;
  phone: {
    hours: string;
    local: string;
    overseas?: string;
  };
};

export const currentCompanyRegistries: Registry[] = [
  {
    address:
      'Computershare Investor Services Pty Limited\nGPO Box 2975\nMelbourne VIC 3001\nAUSTRALIA',
    investorCentreName: 'Computershare Investor Centre',
    key: 'COMPUTERSHARE',
    link: 'https://www-au.computershare.com/investor/',
    phone: {
      hours: 'Monday to Friday\n8:30am to 5:30pm',
      local: '1300 000 000',
      overseas: '+61 0 0000 0000',
    },
  },
  {
    address:
      'Automic Pty Limited\nLevel 5, 126 Phillip Street\nSydney NSW 2000\nAUSTRALIA',
    investorCentreName: 'Automic Investor Centre',
    key: 'AUTOMIC',
    link: 'https://investor.automic.com.au/',
    phone: {
      hours: 'Monday to Friday\n8:30am to 5:30pm',
      local: '1300 000 000',
    },
  },
  {
    address:
      'Link Market Services Limited\n	Locked Bag A14\nSydney South NSW 1235\nAUSTRALIA',
    investorCentreName: 'Link Market Services Investor Centre',
    key: 'LINK',
    link: 'https://investorcentre.linkmarketservices.com.au/Login/Login',
    phone: {
      hours: 'Monday to Friday\n8:30am to 5:30pm',
      local: '1300 554 474',
    },
  },
  {
    address:
      'Boardroom Pty Ltd\nGrosvenor Place\nLevel 12, 225 George Street\nSydney NSW 2000\nAUSTRALIA',
    investorCentreName: 'Boardroom InvestorServe portal',
    key: 'BOARDROOM',
    link: 'https://www.investorserve.com.au/',
    phone: {
      hours: 'Monday to Friday\n8:30am to 5:30pm',
      local: '1300 737 760',
      overseas: '+61 2 9290 9600',
    },
  },
  // {
  //   address:
  //     'Boardroom Pty Ltd\nSuite 8H\n325 Pitt Street\nSydney NSW 2000\nAUSTRALIA',
  //   email: 'admin@advancedshare.com.au',
  //   investorCentreName: 'Advanced share registry investor centre',
  //   key: 'ADVANCED',
  //   link: 'https://www.advancedshare.com.au/Investor-Login',
  //   phone: {
  //     hours: 'Monday to Friday\n8:30am to 5:30pm',
  //     local: '1300 113 258',
  //     overseas: '+61 2 8096 3502',
  //   },
  // },
];
