import React from 'react';
import { Popover } from '@headlessui/react';
import { BellIcon } from '@heroicons/react-v2/24/solid';
import { Typography } from '@leaf/ui';
import { useHubNotificationsQuery } from '@/apollo/generated';
import NotificationEmptyState from '@/components/notification-center/empty-state';
import NotificationLayout from '@/components/notification-center/layout';
import NotificationStatsCard from '@/components/notification-center/stats-card';

interface Props {
  version?: 'mobile' | 'desktop';
}

const NotificationCenter: React.ComponentType<Props> = ({
  version = 'desktop',
}) => {
  const { data: notificationsData, loading: notificationsLoading } =
    useHubNotificationsQuery();

  const totalNotifications =
    notificationsData && notificationsData.hubNotifications
      ? notificationsData.hubNotifications.filter(
          (notification) => !notification.isRead
        ).length
      : 0;

  const renderNotificationsContent = () => {
    if (notificationsLoading) {
      return <>Loading</>;
    }

    if (
      notificationsData?.hubNotifications &&
      notificationsData.hubNotifications.length
    ) {
      return (
        <>
          {notificationsData.hubNotifications.map((notification) => (
            <NotificationStatsCard
              key={`notification-${notification.id}`}
              datetime={notification.insertedAt}
              hubFeature={notification.hubFeature}
              hubFollow={notification.hubFollow}
              isRead={notification.isRead}
              media={notification.media}
              mediaComment={notification.mediaComment}
              mediaCommentLike={notification.mediaCommentLike}
              mediaCommentReply={notification.mediaCommentReply}
              notificationId={notification.id}
              variant={notification.type}
            />
          ))}
        </>
      );
    }

    return <NotificationEmptyState />;
  };

  return (
    <>
      <Popover as="div" className="sm:relative">
        <Popover.Button className="relative w-full focus:outline-none">
          <BellIcon className="h-6 w-6 text-hubs-primary" />
          {totalNotifications ? (
            <Typography
              className="absolute -top-2 left-3 rounded-2xl bg-company-primary px-2 py-0.5 text-company-primary-text"
              component="span"
              variant="badge"
            >
              {totalNotifications > 9 ? '9+' : totalNotifications}
            </Typography>
          ) : undefined}
        </Popover.Button>
        <NotificationLayout totalNotifications={totalNotifications}>
          {renderNotificationsContent()}
        </NotificationLayout>
      </Popover>
    </>
  );
};

export default NotificationCenter;
