import React from 'react';
import { BellIcon } from '@heroicons/react/outline';
import { Typography } from '@leaf/ui';
import { useCurrentCompany } from '@/contexts/current-company-context';

const NotificationEmptyState: React.ComponentType = () => {
  const {
    currentCompany: { name },
    translate,
  } = useCurrentCompany();

  return (
    <div className="mx-auto flex h-[300px] max-w-sm flex-col items-center justify-center space-y-4">
      <div className="flex justify-center">
        <div className="h-12 w-12 rounded-full bg-company-accent p-3 text-company-primary">
          <BellIcon className="h-6 w-6 text-company-primary" />
        </div>
      </div>
      <div className="space-y-1">
        <Typography
          className="text-center text-hubs-primary"
          variant="subtitle-1"
        >
          Your notifications live here
        </Typography>
        <Typography
          className="text-center text-hubs-secondary"
          variant="body-small"
        >{`Follow ${name} ${translate(
          'announcements.lowercase'
        )} , updates, questions and other users to receive notifications here`}</Typography>
      </div>
    </div>
  );
};

export default NotificationEmptyState;
