const routes = {
  accountSettings: {
    href: '/account-settings',
  },
  accountSettingsInvestorStatus: {
    href: '/account-settings/investor-status',
  },
  accountSettingsNotificationSettings: {
    href: '/account-settings/notification-settings',
  },
  activityUpdate: {
    href: (slug: string) => `/activity-updates/${slug}`,
  },
  activityUpdates: {
    href: '/activity-updates',
  },
  announcements: {
    href: '/announcements',
  },
  auth: {
    resetPassword: {
      href: '/auth/reset-password',
    },
    signin: {
      href: () => '/auth/signin',
    },
    signup: {
      href: (email = '') => {
        if (email) {
          const queries = [];
          queries.push(`email=${encodeURIComponent(email)}`);
          return `/auth/signup?${queries.join('&')}`;
        }
        return '/auth/signup';
      },
    },
  },
  governancePage: {
    href: '/governance',
  },
  index: {
    href: '/',
  },
  interactiveAnnouncement: {
    href: (id: string) => `/announcements/${id}`,
  },
  investmentOffer: {
    href: (id?: string) => `/investment-offer/${id}`,
  },
  onboardingComplete: {
    href: () => '/onboarding/complete',
  },
  onboardingSetUsername: {
    href: () => '/onboarding/set-username',
  },
  onboardingShareholderOrHnwStatus: {
    href: () => '/onboarding/shareholder-or-hnw-status',
  },
  onboardingVerifyEmail: {
    href: () => '/onboarding/verify-email',
  },
  profile: {
    href: (username: string) => `/profile/${username}`,
    likes: {
      href: (username: string) => `/profile/${username}/likes`,
    },
    surveys: {
      href: (username: string) => `/profile/${username}/surveys`,
    },
  },
  resetPassword: {
    href: '/reset-password',
  },
  shortenedUrl: {
    href: (hash: string) => `/s/${hash}`,
  },
  welcomePage: {
    href: '/welcome',
  },
};

export default routes;
