import { RefinitivQuoteData } from '@/utils/refinitiv/types';

export function getFieldValue(d: RefinitivQuoteData | undefined, name: string) {
  if (!d || !d.Fields) {
    return '-';
  }
  const value = d.Fields.find((field) => field.Name === name);
  const returnVal =
    value && value[value.DataType] ? value[value.DataType] : '-';
  return returnVal === 0 || returnVal === '0' ? '-' : returnVal;
}
