import cookieParser from 'cookie';

const HERMES_RETURN_TO_COOKIE = '_hermes_return_to';

const HERMES_RETURN_TO_COOKIE_OPTIONS: cookieParser.CookieSerializeOptions = {
  path: '/',
  secure: process.env.NODE_ENV === 'production',
};

export const setReturnToCookie = (pathname: string): void => {
  document.cookie = cookieParser.serialize(
    HERMES_RETURN_TO_COOKIE,
    pathname,
    HERMES_RETURN_TO_COOKIE_OPTIONS
  );
};
export const getReturnToCookiePath = (): string | undefined => {
  const cookie = cookieParser.parse(document.cookie);

  return cookie[HERMES_RETURN_TO_COOKIE];
};
export const clearReturnToCookie = () => {
  document.cookie = cookieParser.serialize(HERMES_RETURN_TO_COOKIE, '', {
    ...HERMES_RETURN_TO_COOKIE_OPTIONS,
    maxAge: 0,
  });
};

export const setHideSurveyCookie = () => {
  document.cookie = cookieParser.serialize('_hermes_hide_survey', 'true', {
    maxAge: 604800,
    path: '/',
  });
};

export const shouldHideSurvey = () => {
  try {
    const cookie = cookieParser.parse(document.cookie);
    return !!cookie['_hermes_hide_survey'];
  } catch (_e) {
    return false;
  }
};
