import { useCallback, useEffect, useMemo, useState } from 'react';
import { Menu } from '@headlessui/react';
import { LogoutIcon } from '@heroicons/react/outline';
import { UserCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { maybeAddRelNofollow } from '@leaf/helpers';
import { Button, Typography } from '@leaf/ui';
import {
  useCurrentInvestorUserQuery,
  useCurrentWelcomePageQuery,
  useLiveCustomCorporatePagesQuery,
  useMediaUpdateDateRangeQuery,
} from '@/apollo/generated';
import CookieBanner from '@/components/cookie-manager/cookie-banner';
import HeadTag from '@/components/layouts/head-tag';
import { FooterLogo, HeaderLogo } from '@/components/layouts/root/logo';
import MobileMenuButton from '@/components/layouts/root/mobile-menu-button';
import SubscribeForm from '@/components/layouts/root/subscribe-form';
import TickerPrice from '@/components/layouts/root/ticker-price';
import NotificationCenter from '@/components/notification-center';
import { useAlert } from '@/contexts/alert-context';
import { useCookieBanner } from '@/contexts/cookie-banner-context';
import { useCurrentCompany } from '@/contexts/current-company-context';
import { useCreateTrackingUtmEvent } from '@/hooks/use-create-tracking-event';
import { FLAGS } from '@/hooks/use-feature-toggles';
import { logout } from '@/utils/auth/auth-helpers';
import {
  clearReturnToCookie,
  setReturnToCookie,
} from '@/utils/redirect-helpers';
import routes from '@/utils/routes';

export type NavigationItem = {
  children?: NavigationItem[];
  current?: boolean;
  external?: boolean;
  href: string;
  name: string;
};

const RootLayout: React.ComponentType<{
  children?: React.ReactNode;
  hideFooterOnMobileAnnouncement?: boolean;
}> = ({ children, hideFooterOnMobileAnnouncement }) => {
  const { formatAndShowError } = useAlert();
  const { currentCompany, isUK, translate } = useCurrentCompany();

  const {
    featuresEnabled,
    hasCompanyShareholderOfferPermission,
    hub,
    investorHub,
    liveInvestmentOfferId,
    logoUrl,
    name,
    sppNavText,
    sppUrl,
    ticker: { listingKey, marketKey, marketListingKey },
  } = currentCompany;

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { setCookieBannerVisible } = useCookieBanner();

  const sppEnabled = sppUrl && featuresEnabled.includes(FLAGS.sppEnabled);

  const currentUserQuery = useCurrentInvestorUserQuery();

  const router = useRouter();
  const { asPath, pathname, query } = router;

  useCreateTrackingUtmEvent();

  useEffect(() => {
    const setupComplete =
      currentUserQuery.data?.currentInvestorUser?.confirmedAt &&
      currentUserQuery.data?.currentInvestorUser?.username;
    if (
      asPath &&
      asPath !== '/' &&
      !asPath.includes(routes.auth.signup.href()) &&
      !setupComplete
    )
      setReturnToCookie(asPath);
    if (setupComplete || (asPath && asPath.includes(routes.auth.signup.href())))
      clearReturnToCookie();
  }, [asPath, query, router, currentUserQuery.data?.currentInvestorUser]);

  const currentWelcomePageQuery = useCurrentWelcomePageQuery({
    variables: {
      listingKey: listingKey,
      marketKey: marketKey,
    },
  });

  const { data: liveCustomCorporatePagesData } =
    useLiveCustomCorporatePagesQuery({
      variables: {
        listingKey: listingKey,
        marketKey: marketKey,
      },
    });

  const { data } = useMediaUpdateDateRangeQuery({
    variables: {
      hub,
    },
  });

  const liveCustomCorporatePagesNavigation = useMemo(() => {
    return liveCustomCorporatePagesData &&
      liveCustomCorporatePagesData.liveCustomCorporatePages &&
      liveCustomCorporatePagesData.liveCustomCorporatePages.length
      ? liveCustomCorporatePagesData.liveCustomCorporatePages.map(
          (liveCustomCorporatePage) => {
            return {
              current:
                [`/_companies/[host]/[slug]`].includes(pathname) &&
                liveCustomCorporatePage.slug === (query.slug as string),
              href: `/${liveCustomCorporatePage.slug}`,
              name: liveCustomCorporatePage.title,
            };
          }
        )
      : [];
  }, [liveCustomCorporatePagesData, pathname, query.slug]);

  const navigation: NavigationItem[] = useMemo(() => {
    const items: {
      current?: boolean;
      external?: boolean;
      href: string;
      name: string;
    }[] = [
      {
        current: ['/_companies/[host]'].includes(pathname),
        href: routes.index.href,
        name: 'Home',
      },
      {
        current: [
          '/_companies/[host]/announcements',
          '/_companies/[host]/announcements/[id]',
        ].includes(pathname),
        href: routes.announcements.href,
        name: translate('announcements.uppercase'),
      },
    ];

    if (currentWelcomePageQuery.data?.currentWelcomePage) {
      items.push({
        current: ['/_companies/[host]/welcome'].includes(pathname),
        href: routes.welcomePage.href,
        name: 'New investors',
      });
    }

    // 1AD want to call Updates in the navbar as News and media
    // Reference: https://investor-hub.slack.com/archives/C04G0GK9NLU/p1701125766085259
    if (data?.mediaUpdateDateRange?.newest) {
      items.push({
        current: ['/_companies/[host]/activity-updates'].includes(pathname),
        href: routes.activityUpdates.href,
        name: ['1ad'].includes(listingKey) ? 'News and media' : 'Updates',
      });
    }

    items.push(...liveCustomCorporatePagesNavigation);

    if (
      !isUK &&
      hasCompanyShareholderOfferPermission &&
      liveInvestmentOfferId
    ) {
      items.push({
        current: ['/_companies/[host]/investment-offer'].includes(pathname),
        href: routes.investmentOffer.href(''),
        name: 'Investment offer',
      });
    }

    if (sppEnabled) {
      items.push({
        external: true,
        href: sppUrl,
        name: sppNavText ?? 'SPP',
      });
    }

    return items;
  }, [
    pathname,
    translate,
    liveCustomCorporatePagesNavigation,
    currentWelcomePageQuery.data?.currentWelcomePage,
    data?.mediaUpdateDateRange?.newest,
    isUK,
    hasCompanyShareholderOfferPermission,
    liveInvestmentOfferId,
    sppEnabled,
    listingKey,
    sppUrl,
    sppNavText,
  ]);

  const handleLogout = useCallback(async () => {
    try {
      await logout();

      window.location.assign(routes.index.href);
    } catch (error) {
      formatAndShowError(error);
    }
  }, [formatAndShowError]);

  const getCompanyDefaultEmail = () => {
    if (isUK) {
      return 'ukclients@investorhub.com';
    }

    return 'clients@investorhub.com';
  };

  return (
    <>
      <HeadTag />
      <div className="flex min-h-screen flex-col">
        <header className="fixed inset-x-0 top-0 z-20 bg-white">
          <div className="relative mx-auto flex h-[72px] max-w-screen-xl items-center gap-4 px-4 sm:gap-6 sm:px-6">
            <div className="flex min-w-0 flex-1 items-center gap-4">
              <Link className="flex" href={routes.index.href}>
                <HeaderLogo
                  listingKey={listingKey}
                  logoUrl={logoUrl}
                  marketKey={marketKey}
                />
              </Link>
              <div className="h-8 w-px bg-secondary-grey-light" />
              <Typography className="text-hubs-secondary" variant="subtitle-2">
                {name}
              </Typography>
            </div>

            <div
              className={clsx(
                'hidden min-w-0 flex-1 items-center justify-end gap-4 lg:flex',
                currentUserQuery.loading && 'opacity-0'
              )}
            >
              <TickerPrice />
              <div className="h-8 w-px bg-secondary-grey-light" />
              {currentUserQuery.data?.currentInvestorUser ? (
                <>
                  <NotificationCenter />
                  <div className="h-8 w-px bg-secondary-grey-light" />
                  <Menu as="div" className="relative">
                    <Menu.Button className="typography-subtitle-2 inline-flex items-center gap-2 text-hubs-secondary">
                      <span data-test="my-account-btn-text">My account</span>
                      <UserCircleIcon className="h-6 w-6" />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 z-30 mt-2 min-w-64 origin-top-right divide-y divide-secondary-grey-light-2 overflow-hidden rounded-md border bg-white shadow-md">
                      <Menu.Item>
                        <div className="p-4">
                          <Typography className="text-hubs-secondary">
                            Signed in as
                          </Typography>
                          <Typography
                            className="mt-1 text-hubs-secondary"
                            variant="subtitle-2"
                          >
                            {currentUserQuery.data.currentInvestorUser.email}
                          </Typography>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div>
                          <Link
                            className="typography-subtitle-2 flex h-[52px] items-center px-4 hover:bg-hubs-background-accent"
                            href={routes.accountSettings.href}
                          >
                            Account settings
                          </Link>
                        </div>
                      </Menu.Item>
                      {currentUserQuery.data.currentInvestorUser.username ? (
                        <Menu.Item>
                          <div>
                            <Link
                              className="typography-subtitle-2 flex h-[52px] items-center px-4 hover:bg-hubs-background-accent"
                              href={routes.profile.href(
                                currentUserQuery.data.currentInvestorUser
                                  .username
                              )}
                            >
                              Profile
                            </Link>
                          </div>
                        </Menu.Item>
                      ) : null}
                      <Menu.Item>
                        <button
                          className="typography-subtitle-2 flex h-[52px] w-full items-center gap-1 px-4 text-status-red hover:bg-hubs-background-accent"
                          data-test="sign-out-nav-btn"
                          type="button"
                          onClick={handleLogout}
                        >
                          <LogoutIcon className="h-6 w-6" />
                          Sign out
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Menu>
                </>
              ) : (
                <>
                  <Link href={routes.auth.signin.href()}>
                    <Button
                      variant="tertiary"
                      onClick={() => setReturnToCookie(router.asPath)}
                    >
                      Log in
                    </Button>
                  </Link>
                  <Link href={routes.auth.signup.href()}>
                    <Button
                      testId="sign-up-nav-btn"
                      variant="primary"
                      onClick={() => setReturnToCookie(router.asPath)}
                    >
                      Sign up
                    </Button>
                  </Link>
                </>
              )}
            </div>

            {currentUserQuery.data?.currentInvestorUser && (
              <div className="inline-flex lg:hidden">
                <NotificationCenter />
              </div>
            )}

            <MobileMenuButton
              handleLogout={handleLogout}
              isLoggedIn={!!currentUserQuery.data?.currentInvestorUser}
              navMenuItems={[
                ...navigation,
                {
                  current: false,
                  href: investorHub?.website ?? routes.index.href,
                  name: `Back to ${name}`,
                },
              ]}
              open={mobileNavOpen}
              setOpen={setMobileNavOpen}
            />
          </div>

          {/* This absolute div goes underneath the div following, so that the grey background goes all the way to the edges */}
          <div className="bg-company-accent text-company-accent-text">
            <div className="z-10 mx-auto hidden h-[48px] max-w-screen-xl items-center gap-4 px-4 sm:gap-6 sm:px-6 lg:flex">
              <div className="items-start space-x-8">
                {navigation.map((nav) =>
                  nav.external ? (
                    <a
                      key={nav.name}
                      className={clsx(
                        'typography-subtitle-2 inline-flex h-8 items-center gap-2 border-b-2',
                        nav.current
                          ? 'border-b-company-primary text-company-primary'
                          : 'border-b-transparent'
                      )}
                      href={nav.href}
                      rel="noreferrer"
                      target="_blank"
                      onClick={() => setMobileNavOpen(false)}
                    >
                      {nav.name}
                      <span className="my-auto child:stroke-company-primary">
                        <svg
                          fill="none"
                          height="16"
                          viewBox="0 0 16 16"
                          width="16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.33325 2.99992H2.99992C2.55789 2.99992 2.13397 3.17551 1.82141 3.48807C1.50885 3.80063 1.33325 4.22456 1.33325 4.66659V12.9999C1.33325 13.4419 1.50885 13.8659 1.82141 14.1784C2.13397 14.491 2.55789 14.6666 2.99992 14.6666H11.3333C11.7753 14.6666 12.1992 14.491 12.5118 14.1784C12.8243 13.8659 12.9999 13.4419 12.9999 12.9999V9.66659M9.66659 1.33325H14.6666M14.6666 1.33325V6.33325M14.6666 1.33325L6.33325 9.66659"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          />
                        </svg>
                      </span>
                    </a>
                  ) : (
                    <Link
                      key={nav.name}
                      className={clsx(
                        'typography-subtitle-2 inline-flex h-8 items-center border-b-2',
                        nav.current
                          ? 'border-b-company-primary text-company-primary'
                          : 'border-b-transparent'
                      )}
                      href={nav.href}
                    >
                      {nav.name}
                    </Link>
                  )
                )}
              </div>
              <span className="grow"></span>
              <Link
                className="typography-subtitle-2 flex items-end space-x-2 whitespace-nowrap text-company-primary"
                href={investorHub?.website ?? routes.index.href}
                rel="noopener noreferrer"
                target="_blank"
              >
                <p>Back to {name} </p>
                <span className="my-auto">
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.33325 2.99992H2.99992C2.55789 2.99992 2.13397 3.17551 1.82141 3.48807C1.50885 3.80063 1.33325 4.22456 1.33325 4.66659V12.9999C1.33325 13.4419 1.50885 13.8659 1.82141 14.1784C2.13397 14.491 2.55789 14.6666 2.99992 14.6666H11.3333C11.7753 14.6666 12.1992 14.491 12.5118 14.1784C12.8243 13.8659 12.9999 13.4419 12.9999 12.9999V9.66659M9.66659 1.33325H14.6666M14.6666 1.33325V6.33325M14.6666 1.33325L6.33325 9.66659"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </header>
        <main
          className={clsx(
            'flex flex-col pt-[72px] lg:mt-[48px]',
            hideFooterOnMobileAnnouncement &&
              'bg-hubs-background-accent md:bg-white'
          )}
        >
          {children}
        </main>

        <footer
          className={clsx(
            'border-t border-t-secondary-grey-light bg-company-accent py-16 text-company-accent-text',
            !currentUserQuery.loading &&
              !currentUserQuery.data?.currentInvestorUser &&
              hideFooterOnMobileAnnouncement &&
              'hidden md:block'
          )}
        >
          <div className="mx-auto grid max-w-screen-xl grid-cols-5 gap-6 px-4 sm:px-6">
            <div className="col-span-5 flex flex-col items-center sm:min-h-[132px] md:col-span-2 md:items-start lg:col-span-3">
              <FooterLogo
                listingKey={listingKey}
                logoUrl={logoUrl}
                marketKey={marketKey}
              />
              <Typography
                className="mt-2 text-center font-body sm:text-left"
                variant="subtitle-1"
              >
                {name}{' '}
                <span className="hidden sm:inline-block">investor hub</span>
              </Typography>
            </div>

            {!currentUserQuery.loading &&
              !currentUserQuery.data?.currentInvestorUser && (
                <div className="col-span-5 md:col-span-3 lg:col-span-2">
                  <Typography
                    className="text-center font-heading sm:text-left"
                    variant="small"
                  >
                    Stay in the loop.
                  </Typography>
                  <Typography className="mb-4 mt-1 text-center font-body sm:text-left">
                    {/* Remove the trailing "." from the company name to avoid scenario ".." */}
                    Subscribe to receive {translate('announcements.lowercase')}{' '}
                    and updates from {name.replace(/\.$/, '')}.
                  </Typography>
                  <SubscribeForm event="subscribed_from_embed" />
                </div>
              )}
          </div>
          <div className="mx-auto mt-20 flex max-w-screen-xl flex-col-reverse items-center justify-between gap-6 border-t border-t-secondary-grey-light px-4 py-6 sm:flex-row sm:px-6">
            <Typography
              className="flex flex-wrap items-center justify-center gap-1 sm:justify-start"
              component="div"
              variant="body-small"
            >
              <a
                className="hover:opacity-90"
                href="https://www.investorhub.com"
                rel={`noreferrer ${maybeAddRelNofollow(marketListingKey)}`}
                target="_blank"
              >
                InvestorHub
              </a>
              <span>•</span>
              <a
                className="hover:opacity-90"
                href={
                  isUK
                    ? 'https://investorhub.com/uk-microsite-terms-and-conditions'
                    : 'https://investorhub.com/au-microsite-terms-and-conditions'
                }
                rel={`noreferrer ${maybeAddRelNofollow(marketListingKey)}`}
                target="_blank"
              >
                Terms and conditions
              </a>
              <span>•</span>
              <a
                className="hover:opacity-90"
                href={
                  isUK
                    ? 'https://investorhub.com/uk-microsite-privacy-policy'
                    : 'https://investorhub.com/au-microsite-privacy-policy'
                }
                rel={`noreferrer ${maybeAddRelNofollow(marketListingKey)}`}
                target="_blank"
              >
                Privacy policy
              </a>
              <span>•</span>
              <span
                className="hover:cursor-pointer hover:opacity-90"
                onClick={() => setCookieBannerVisible(true)}
              >
                Cookie settings
              </span>
              <span>•</span>
              {marketKey === 'asx' && listingKey === 'sgl' && (
                <>
                  <a
                    className="hover:opacity-90"
                    href="https://storage.googleapis.com/leaf-prod/uploads/public/20241031_Personal%20Information%20Collection%20Statement%20-%20Shareholders%20-%20InvestorHub.pdf"
                    rel={`noreferrer ${maybeAddRelNofollow(marketListingKey)}`}
                    target="_blank"
                  >
                    Collection Statement
                  </a>
                  <span>•</span>
                </>
              )}
              <a
                className="hover:opacity-90"
                href={`mailto:${getCompanyDefaultEmail()}?subject=Technical%20Issue%20-%20${marketKey.toUpperCase()}%3A${listingKey.toUpperCase()}&body=Any%20data%20provided%20will%20be%20solely%20used%20to%20process%20your%20request%2C%20which%20may%20take%20up%20to%2030%20days.%0A%0APlease%20provide%20the%20details%20of%20your%20issue%20here%3A`}
                rel="noopener noreferrer"
              >
                Technical issues?
              </a>
            </Typography>

            <div className="flex gap-6 fill-company-primary">
              {investorHub?.twitter && (
                <a
                  href={investorHub.twitter}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="h-6 w-6 hover:opacity-90"
                    role="img"
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Twitter</title>
                    <path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z" />
                  </svg>
                </a>
              )}
              {investorHub?.linkedin && (
                <a
                  href={investorHub.linkedin}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="h-6 w-6 hover:opacity-90"
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>LinkedIn</title>
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                  </svg>
                </a>
              )}
              {investorHub?.facebook && (
                <a
                  href={investorHub.facebook}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="h-6 w-6 hover:opacity-90"
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Facebook</title>
                    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                  </svg>
                </a>
              )}
              {investorHub?.instagram && (
                <a
                  href={investorHub.instagram}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="h-6 w-6 hover:opacity-90"
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Instagram</title>
                    <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                  </svg>
                </a>
              )}
            </div>
          </div>
        </footer>
      </div>
      <CookieBanner />
    </>
  );
};

export default RootLayout;
