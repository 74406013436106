import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { Checkbox as UICheckbox } from '@leaf/ui';

type CheckboxProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Checkbox: React.ComponentType<CheckboxProps> = ({ ...props }) => {
  return <UICheckbox {...props} />;
};

export default Checkbox;
